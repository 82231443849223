define('ember-cli-spinjs/components/ember-spinner', ['exports', 'spin'], function (exports, _spin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'spinner-display',
    color: '#333',
    corners: 1,
    direction: 1,
    fps: 20,
    left: '50%',
    length: 7,
    lines: 12,
    opacity: 1 / 4,
    radius: 10,
    rotate: 0,
    scale: 1.0,
    shadow: false,
    speed: 1,
    top: '50%',
    trail: 100,
    width: 5,
    zIndex: 2000000000,
    position: 'absolute',
    spinner: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.configArgs = {};
    },
    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments);
      var opts = {
        color: this.get('color'),
        corners: this.get('corners'),
        direction: this.get('direction'),
        fps: this.get('fps'),
        left: this.get('left'),
        length: this.get('length'),
        lines: this.get('lines'),
        opacity: this.get('opacity'),
        radius: this.get('radius'),
        rotate: this.get('rotate'),
        scale: this.get('scale'),
        shadow: this.get('shadow'),
        speed: this.get('speed'),
        top: this.get('top'),
        trail: this.get('trail'),
        width: this.get('width'),
        zIndex: this.get('zIndex'),
        position: this.get('position'),
        hwaccel: true
      };

      var configArgs = void 0;

      if (this.get('config')) {
        var modulePrefix = this.emberSpinnerPrefixConfig.modulePrefix;
        var configFile = modulePrefix + '/config/ember-spinner/' + this.get('config');

        configArgs = require(configFile).default;
      }

      this.spinnerArgs = Ember.merge(opts, configArgs);
    },
    didInsertElement: function didInsertElement() {
      this.spinner = new _spin.Spinner(this.spinnerArgs).spin(this.element);
    },
    willRemoveElement: function willRemoveElement() {
      this.spinner.stop();
    }
  });
});